import { graphql, PageProps } from 'gatsby'
import React, { FC } from 'react'

import { ProductPageTemplateQuery } from '../../../graphql/gatsby'
import Head from '../../../layouts/Head'
import { ProductPageContext } from '../../../lib/pages'
import { isSanityImageObject } from '../../../lib/sanity'
import Layout from '../../global/Layout'
import ItemContainer from './ItemContainer'

export type ProductPageProps = Pick<
  PageProps<ProductPageTemplateQuery, ProductPageContext>,
  'data' | 'pageContext'
>

export const ProductPage: FC<ProductPageProps> = ({ data, pageContext: { urlKey } }) => {
  const parentProduct = data.sanityParentProduct
  const childProducts = parentProduct?.products

  return (
    <>
      {parentProduct ? (
        <Head
          title={parentProduct.title || 'Product'}
          description={parentProduct.metaDescription}
          keywords={parentProduct.keywords}
          image={
            isSanityImageObject(parentProduct.openGraphImage)
              ? parentProduct.openGraphImage
              : isSanityImageObject(parentProduct.image)
                ? parentProduct.image
                : undefined
          }
        />
      ) : (
        <Head title="Product" />
      )}
      <Layout>
        <ItemContainer
          pageContext={{ urlKey }}
          sanityParentProduct={parentProduct}
          sanityChildProducts={childProducts}
        />
      </Layout>
    </>
  )
}

export const sanityParentProductFragment = graphql`
  fragment sanityParentProduct on SanityParentProduct {
    keywords
    metaDescription
    shortDescription
    _rawDefaultDescription
    _rawContextualDescription
    sku
    tagline
    title
    brand
    image {
      ...SanityImageObject
    }
    headerImage {
      ...SanityImageObject
    }
    openGraphImage {
      ...SanityImageObject
    }
    primaryColor {
      color
    }
    relatedProductsTitle
    relatedProductsSubtitle
    relatedProducts {
      sku
      tagline
      shortDescription
    }
    products {
      ... on SanityProduct {
        id
        sku
        _rawDefaultDescription
        _rawContextualDescription
        prices {
          retail {
            amount
            currency
          }
        }
        mainImage {
          alt
          imageUrl: asset {
            url
          }
        }
        otherImages {
          ... on SanityProductImage {
            alt
            imageUrl: asset {
              url
            }
          }
        }
      }
    }
  }
`
